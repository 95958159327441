import { GetServerSideProps } from 'next';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useIntl } from 'react-intl';
import { lazy, useEffect, useState, type ComponentType, type FC } from 'react';

import AuthoredPageRoutes from '@/components/authored-page-routes/authored-page-routes';
import SeoWrapper from '@/components/seo-wrapper/seo-wrapper';
import { PreRenderProps } from '@/pages/_app';
import { getViewDefinition } from '@/ssr/view-definition-page-query';
import { getPdpViewDefinition } from '@/ssr/view-definition-pdp-page-query';
import { fetchDataAndHandleErrors } from '@/utils/ssr-utils';
import { useDetermineBanner } from '@/hooks';
import { Spinner } from '@/components/shared';
import { HOST } from '@/data/constants';

// @ts-ignore: federated module
// const PDP = dynamic(() => import('beautyFrontendPlp/Pdp'), { ssr: false, loading: () => <Spinner /> });

// @ts-ignore
export const getServerSideProps: GetServerSideProps<PreRenderProps> = async (context) => {
  const isPdpPage = context.resolvedUrl.includes('/p/');
  const data = isPdpPage
    ? await getPdpViewDefinition(context)
    : await fetchDataAndHandleErrors(context, getViewDefinition, undefined);

  return { props: data };
};

const SDMRoutes = (props: any) => {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const isPharmaprix = useDetermineBanner();
  const currentUrlBase = isPharmaprix ? HOST.PHX_HOME : HOST.SDM_HOME;
  const isPdpPage = router.asPath.includes('/p/');
  const [PDP, setPDP] = useState<ComponentType<any> | null>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // @ts-ignore
      setPDP(lazy(() => import('beautyFrontendPlp/Pdp')));
    }
  }, []);

  function constructPdpMetaData({ viewDefinition }: any) {
    const bannerName = isPharmaprix ? 'Pharmaprix' : 'Shoppers Drug Mart';
    const productBrand = viewDefinition?.brandName;
    const productTitle = viewDefinition?.name;
    const headline = `${productBrand} ${productTitle} | ${bannerName}`;
    const description = formatMessage({ id: 'pdpSeoDescription' }, { productTitle, productBrand, bannerName });

    return {
      headline,
      description,
      addMetaNoRobots: false,
      canonicalUrlSlug: viewDefinition?.url,
      jsonLd: null,
    };
  }

  if (isPdpPage) {
    const viewData = constructPdpMetaData(props);
    return (
      <SeoWrapper seoMetaData={viewData} currentUrlBase={currentUrlBase} jsonLdScript="">
        {PDP ? <PDP /> : <Spinner />}
      </SeoWrapper>
    );
  }

  return <AuthoredPageRoutes {...props} />;
};

export default SDMRoutes;
